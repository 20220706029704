import React from 'react'
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import components from "../components/mdx_components";
import {MDXRenderer} from "gatsby-plugin-mdx";
import {MDXProvider} from "@mdx-js/react";
import {graphql} from "gatsby";
import GatsbyImage from "gatsby-image";
import SEO from "../components/seo";
import VideoCard from "../components/VideoCard";
import EventsCard from "../components/EventsCard";
import Accordion from "@material-ui/core/Accordion";
import {Typography} from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Page = ({data}) => {
  const events = data.events.nodes.filter(x => new Date(x.frontmatter.date) > new Date())

  return (
    <React.Fragment>
      <SEO title="Shiatsu"/>
      <Container maxWidth={"md"}>
        <Box my={1}>
          <Card>
            <GatsbyImage fluid={data.file.childImageSharp.fluid}/>
            <Box m={1}>
              <MDXProvider components={components}><MDXRenderer>{data.shiatsu.body}</MDXRenderer></MDXProvider>
            </Box>
          </Card>
        </Box>
        {events.length > 0 ? (
          <Box my={1}>
            <EventsCard events={events}/>
          </Box>
        ) : null}
        <Box my={1}>
          <VideoCard videoId={data.video.frontmatter.videoId} caption={data.video.frontmatter.caption}/>
        </Box>
        <Box my={1}>
          <Card>
            <Accordion variant="elevation" elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content">
                <Typography variant="h4" gutterBottom={false}>
                  Ethik in der Arbeit mit Shiatsu
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <MDXProvider components={components}><MDXRenderer>{data.arbeitsethik.body}</MDXRenderer></MDXProvider>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default Page

export const pageQuery = graphql`
    query {
        video: mdx(frontmatter: { title: { eq: "vidShiatsu" } }) {
            frontmatter {
                videoId
                caption
            }
        }
        shiatsu: mdx(frontmatter: { title: { eq: "Shiatsu" } }) {
            body
        }
        events: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {slug: {regex: "/events/"}, frontmatter: {topic: {eq: "shiatsu"}}}) {
            nodes {
                id
                body
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    time: date(formatString: "HH:mm")
                    title
                    location
                    price
                }
            }
        }
        arbeitsethik: mdx(frontmatter: { title: { eq: "Arbeitsethik" } }) {
            body
        }
        file(relativePath: { eq: "images/shiatsu.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 882, maxHeight: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

